import { SideNavItems, SideNavSection } from '../models';

export const sideNavSections: SideNavSection[] = [
    {
        text: 'PLATIA',
        items: ['dashboard'],
    },
    {
        text: 'OPERACIONES',
        items: ['depositos', 'retiros'],
    },
    {
        text: 'SOCIOS',
        items: ['socios'],
    },
    
    {
        text: 'NEGOCIOS',
        items: ['negocios', 'ahorros','plazofijo'],
    },
    {
        text: 'ADMINISTRACION',
        items: ['administracion'],
    },
    {
        text: 'CONFIGURACION',
        items: ['usuarios', 'conciliacion'],
    },/*
    {
        text: 'INTERFACE',
        items: ['layouts', 'pages'],
    },
    {
        text: 'ADDONS',
        items: ['charts', 'tables'],
    },*/
];

export const sideNavItems: SideNavItems = {
    dashboard: {
        icon: 'speedometer',
        text: 'Dashboard',
        link: '/dashboard',
    },
    depositos: {
        icon: 'plus-circle',
        text: 'Depositos',
        link: '/depositos',
    },
    retiros: {
        icon: 'dash-circle',
        text: 'Retiros',
        link: '/retiros',
    },
    socios: {
        icon: 'people-fill',
        text: 'Socios',
        link: '/socios',
    },
    negocios: {
        icon: 'shop',
        text: 'Negocios',
        link: '/negocios',
    },
    ahorros: {
        icon: 'piggy-bank',
        text: 'Ahorros',
        link: '/ahorros',
    },
    plazofijo: {
        icon: 'bank',
        text: 'Plazo Fijo',
        link: '/plazofijos',
    },
    administracion: {
        icon: 'person-lock',
        text: 'Administración',
        link: '/servicios',
    },
    usuarios: {
        icon: 'person-badge-fill',
        text: 'Usuarios',
        link: '/usuarios',
    },
    conciliacion: {
        icon: 'file-text blue-color',
        text: 'Conciliacion',
        link: '/conciliacion',
    },
    /*
    layouts: {
        icon: 'columns',
        text: 'Layouts',
        submenu: [
            {
                text: 'Static Navigation',
                link: '/dashboard/static',
            },
            {
                text: 'Light Sidenav',
                link: '/dashboard/light',
            },
        ],
    },
    pages: {
        icon: 'book-open',
        text: 'Pages',
        submenu: [
            {
                text: 'Authentication',
                submenu: [
                    {
                        text: 'Login',
                        link: '/auth/login',
                    },
                    {
                        text: 'Register',
                        link: '/auth/register',
                    },
                    {
                        text: 'Forgot Password',
                        link: '/auth/forgot-password',
                    },
                ],
            },
            {
                text: 'Error',
                submenu: [
                    {
                        text: '401 Page',
                        link: '/error/401',
                    },
                    {
                        text: '404 Page',
                        link: '/error/404',
                    },
                    {
                        text: '500 Page',
                        link: '/error/500',
                    },
                ],
            },
        ],
    },*/
    charts: {
        icon: 'chart-area',
        text: 'Charts',
        link: '/charts',
    },
    tables: {
        icon: 'table',
        text: 'Tables',
        link: '/tables',
    },
};
