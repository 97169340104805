import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { Iobjectwrapper } from '../interfaces/iobjectwrapper';
import { map } from 'rxjs/operators';
import { Inote } from '../interfaces/inote';
import { Ibusiness } from '../interfaces/ibusiness';

@Injectable({
  providedIn: 'root'
})
export class BusinessService extends BaseService<Ibusiness>{

  constructor(httpClient:HttpClient, private httpClient2: HttpClient) {
    super(httpClient, environment.APIREST, 'businesses', 'photos');
   }
   
   

    add(key: string, business: Ibusiness){
      
      return this.httpClient2.post<Iapiresponse<Iobjectwrapper<Ibusiness>>>(`${environment.APIREST}/${'businesses'}/${key}`, business, this.httpOptions)
        .pipe(
          map(e=>e.result)
        );
    }
}
