import { Injectable } from "@angular/core";
//import moment from 'moment';
import moment from 'moment';
//import * as data from '../../assets/data.json';
import data from '../../assets/data.json';




export enum TxnState {
    CREATED = "PENDIENTE",
    ACCEPTED = "APROBADO",
    CANCELLED = "RECHAZADO"
}

export enum TxnType {
    DEPOSIT = "DEPOSITO",
    WITHDRAWAL = "RETIRO"
}

export enum MemberState {
    CREATED = "CREADO",
    ACTIVE = "ACTIVO",
    SUSPENDED = "SUSPENDIDO",
    CLOSED = "DEBAJA"
}

export enum MemberInteropState {
    PENDING = "PENDIENTE",
    ACTIVE = "ACTIVO",
    SUSPENDED = "SUSPENDIDO",
    BLOCKED = "BLOQUEADO",
    CLOSED = "BAJA"
}

export enum MemberKycState {
    PENDING = "PENDIENTE",
    ACTIVE = "VALIDADO",
    REJECTED = "RECHAZADO",
}

export enum BusinessState {
    CREATED = "CREADO",
    ACTIVE = "ACTIVO",
    SUSPENDED = "SUSPENDIDO",
    CLOSED = "CERRADO"
}

export enum LoanState {
    CREATED = "POR EVALUAR",
    REVIEWED = "EVALUADO",
    APPROVED = "APROBADO",
    DENIED = "DENEGADO"

}

export enum ProductLoanState {
    ENABLED = "ACTIVO",
    DISABLED = "INACTIVO"
}

export enum ProductSavingsState {
    ENABLED = "VIGENTE",
    DISABLED = "INACTIVO"
}

export enum SavingState {
    CREATED = "CREADO",
    ENABLED = "ACTIVO",
    DISABLED = "INACTIVO"
}

export enum NoteType {
    SYSTEM = "SISTEMA",
    MANUAL = "MANUAL"
}

export enum MemberPhotoType {
    DNIFRONT = 0,
    DNIREVERSE = 1,
    DNISELFIE = 2,
    RECEIPT = 3,
    FACADE1 = 4,
    FACADE2 = 5,
    FACADE3 = 6,
    FACADE4 = 7
}


export enum BusinessPhotoType {
    FACADE1 = 4,
    FACADE2 = 5,
    FACADE3 = 6,
    FACADE4 = 7
}

export enum TXNCHANNEL {
    BANKING = 1,
    ONSITE = 0
}
// Socket.io events
export enum Event {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect'
}

export enum LoanEvaluationState {
    APPROVED = "APROBAR",
    DECLINED = "RECHAZAR"
}

export enum LoanEvaluationInterviewType {
    TELEPHONE = "TELEF",
    OFFICE = "OFI",
    VIDEOCALL = "VIDEO",
    ONSITE = "ONSITE"

}


export enum ChargeType {
    PERCENTAGE = "PORCENTAJE",
    AMOUNT = "MONTO"
}

export enum ChargeState {
    ENABLED = "VIGENTE",
    SUSPENDED = "SUSPENDIDO"
}

export enum Product {
    SAVINGS = "AHORROS",
    LOANS = "PRESTAMOS",
    CD = "PLAZOFIJO"
}

export enum BoolEnum {
    TRUE = "Sí",
    FALSE = "No"
}

export enum UserState {
    ENABLED = "ACTIVO",
    SUSPENDED = "SUSPENDED",
    DISABLED = "BAJA"
}

export enum AccountState {
    ENABLED = "ACTIVO",
    DISABLED = "INACTIVO",
    OBSOLETE = "BAJA"
}

export enum AccountNature {
    DEBIT = "DEUDORA",
    CREDIT = "ACREEDORA"
}

export enum OperationType {
    DAILY = "DIARIO",
    PURCHASES = "COMPRAS",
    CASH = "CAJA"
}

export enum OperationColumn {
    DEBIT = "DEBE",
    CREDIT = "HABER"
}

export enum OperationMonth {
    JANUARY ="ENERO",
    FEBRUARY ="FEBRERO",
    MARCH = "MARZO",
    APRIL = "ABRIL",
    MAY = "MAYO",
    JUNE = "JUNIO",
    JULY= "JULIO",
    AUGUST= "AGOSTO",
    SEPTEMBER = "SETIEMBRE",
    OCTOBER = "OCTUBRE",
    NOVEMBER = "NOVIEMBRE",
    DECEMBER=  "DICIEMBRE"
}
//estados permitidos para operaciones
export enum PermittedState {
    CREATED = "CREADO",
    ACTIVE = "ACTIVO"
}
export class Constants {
    public static memberPhotoTypeDesc:Array<any> = (data as any).memberPhotoTypeDesc;
    public static businessPhotoTypeDesc:Array<any> = (data as any).businessPhotoTypeDesc;
    public static memberDocTypeDesc:Array<any> = (data as any).memberDocTypeDesc;
    //Motives of Member and Business Actions
    public static motivesOfSuspension:Array<any> = (data as any).motivesOfSuspension;
    public static motivesOfActivation:Array<any> = (data as any).motivesOfActivation;
    public static motivesOfClosure:Array<any> = (data as any).motivesOfClosure;
    public static motivesOfResetPass:Array<any> = (data as any).motivesOfResetPass;
    //Motives of Decline of Txns
    public static comboDeclineCashDepositMotives:Array<any> = (data as any).comboDeclineCashDepositMotives;
    public static comboDeclineCashWithdrawalMotives:Array<any> = (data as any).comboDeclineCashWithdrawalMotives;
    
    public static comboDeclineTxfDepositMotives:Array<any> = (data as any).comboDeclineTxfDepositMotives;
    public static comboDeclineTxfWithdrawalMotives:Array<any> = (data as any).comboDeclineTxfWithdrawalMotives;
    
    public static comboBusinessFieldValues:Array<any> = (data as any).comboBusinessFieldValues;
    public static comboMemberFieldValues:Array<any> = (data as any).comboMemberFieldValues;

    public static comboTxnFieldValues:Array<any> = (data as any).comboTxnFieldValues;

    public static comboLoansFieldValues:Array<any> = (data as any).comboLoansFieldValues;

    public static comboProductLoansFieldValues:Array<any> = (data as any).comboProductLoansFieldValues;

    public static comboSavingsFieldValues:Array<any> = (data as any).comboSavingsFieldValues;

    public static comboChargesFieldValues:Array<any> = (data as any).comboChargesFieldValues;
    
    public static comboUsersFieldValues:Array<any> = (data as any).comboUsersFieldValues;

    public static loginErrorCodesList = [
        {
            code: "auth/invalid-email",
            desc: "Ingrese un email válido"
        },
        {
            code: "auth/user-not-found",
            desc: "Usuario y/o Contraseña inválidos"
        },
        {
            code: "auth/wrong-password",
            desc: "Usuario y/o Contraseña inválidos"
        },
        {
            code: "auth/network-request-failed",
            desc: "Error de conexión vuelva a intentar"
        }
    ]
    
    public static interopStatusList = [
        // "BLOQUEADO",
        MemberInteropState.ACTIVE,
        MemberInteropState.SUSPENDED,
        MemberInteropState.CLOSED
    ]
}


@Injectable({
    providedIn: 'root'
})
export class ComboOptionsService {


    private today: Date;
    private firstDateOfMonth: Date;
    private firstDateOfLastMonth: Date;
    

    constructor() {
        this.today = new Date();
        this.today.setHours(23, 59, 59, 0);
        
        this.firstDateOfMonth = new Date();
        this.firstDateOfMonth.setDate(1);
        
        this.firstDateOfLastMonth = new Date();
        this.firstDateOfLastMonth.setDate(1);
        this.firstDateOfLastMonth.setMonth(this.today.getMonth() - 1);
       
    }

    public getNoteComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "date",
                        operator: "<=",
                        value: this.today.getTime()
                    },
                    {
                        field: "date",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime()
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "date",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime()
                    },
                    {
                        field: "date",
                        operator: ">",
                        value: this.firstDateOfLastMonth.getTime()
                    }
                ]
            },
            {
                desc: "Evento Operador",
                id: 4,
                filters: [
                    {
                        field: "type",
                        operator: "==",
                        value: NoteType.MANUAL
                    }
                ]
            },
            {
                desc: "Evento Sistema",
                id: 5,
                filters: [
                    {
                        field: "type",
                        operator: "==",
                        value: NoteType.SYSTEM
                    }
                ]
            }
        ]
    }


    public getBusinessComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<=",
                        value: this.today.getTime()
                    },
                    {
                        field: "createdAt",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime()
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime()
                    },
                    {
                        field: "createdAt",
                        operator: ">",
                        value: this.firstDateOfLastMonth.getTime()
                    }
                ]
            },
            {
                desc: "Estado: ACTIVOS",
                id: 4,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: BusinessState.ACTIVE
                    }
                ]
            },
            {
                desc: "Estado: SUSPENDIDOS",
                id: 5,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: BusinessState.SUSPENDED
                    }
                ]
            },
            {
                desc: "Estado: CERRADO",
                id: 6,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: BusinessState.CLOSED
                    }
                ]
            }
        ]
    }

    public getMemberComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: "date"
                    },
                    {
                        field: "createdAt",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: "date"
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime(),
                        type: "date"
                    },
                    {
                        field: "createdAt",
                        operator: ">=",
                        value: this.firstDateOfLastMonth.getTime(),
                        type: "date"
                    }
                ]
            },
            {
                desc: "Estado: ACTIVOS",
                id: 4,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: MemberState.ACTIVE
                    }
                ]
            },
            {
                desc: "Estado: SUSPENDIDOS",
                id: 5,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: MemberState.SUSPENDED
                    }
                ]
            },
            {
                desc: "Estado: DEBAJA",
                id: 6,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: MemberState.CLOSED
                    }
                ]
            },
            {
                desc: "Val. Identidad: PENDIENTE",
                id: 7,
                filters: [
                    {
                        field: "kycStatus",
                        operator: "==",
                        value: MemberKycState.PENDING
                    }
                ]
            }
        ]
    }


    public getTxnComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<=",
                        value: this.today.getTime()
                    },
                    {
                        field: "createdAt",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime()
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdAt",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime()
                    },
                    {
                        field: "createdAt",
                        operator: ">",
                        value: this.firstDateOfLastMonth.getTime()
                    }
                ]
            },
            {
                desc: "Estado: PENDIENTES",
                id: 4,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: TxnState.CREATED
                    }
                ]
            },
            {
                desc: "Estado: APROBADAS",
                id: 5,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: TxnState.ACCEPTED
                    }
                ]
            }
        ]
    }

    getCashFlowComboOptions() {
        //Monday to Sunday
        //let dateStartPrev = moment().isoWeekday('Monday').toDate();
        //let endDatePrev = moment().isoWeekday('Sunday').toDate();
        let dateStartPrev = moment().startOf('week').toDate();
        let endDatePrev = moment().endOf('week').toDate();

        dateStartPrev.setDate(dateStartPrev.getDate()-7);
        endDatePrev.setDate(endDatePrev.getDate()-7);

        return [
            {
                desc: "Esta Semana",
                id: 1,
                //Sunday to Monday
                startDate:moment().startOf("week").toDate(),
                endDate:moment().endOf("week").toDate()
                //Moday to Sunday
                //startDate: moment().isoWeekday('Monday').toDate(),
                //endDate: moment().isoWeekday('Sunday').toDate()
            },
            {
                desc: "Semana Anterior",
                id: 2,
                startDate: dateStartPrev,
                endDate: endDatePrev
            },

            {
                desc: "Mensual",
                id: 3,
                startDate: moment().startOf("month").toDate(),
                endDate: moment().endOf("month").toDate()
            }
        ]
    }

    public getLoansComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "date",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: 'date'
                    },
                    {
                        field: "date",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "date",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime()
                    },
                    {
                        field: "date",
                        operator: ">=",
                        value: this.firstDateOfLastMonth.getTime()
                    }
                ]
            },
            {
                desc: "Estado: POR EVALUAR",
                id: 4,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: LoanState.CREATED
                    }
                ]
            },
            {
                desc: "Estado: REVISADO",
                id: 5,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: LoanState.REVIEWED
                    }
                ]
            },
            {
                desc: "Estado: APROBADO",
                id: 6,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: LoanState.APPROVED
                    }
                ]
            },
            {
                desc: "Estado: DENEGADO",
                id: 7,
                filters: [
                    {
                        field: "status",
                        operator: "==",
                        value: LoanState.DENIED
                    }
                ]
            }
        ]
    }

    public getProductLoansComboOptions() {
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">=",
                        value: this.firstDateOfLastMonth.getTime(),
                        type: 'date'
                    }
                ]
            }
        ]
    }
    public getSavingsComboOptions(){
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">=",
                        value: this.firstDateOfLastMonth.getTime(),
                        type: 'date'
                    }
                ]
            }
        ]
    }

    public getChargesComboOptions(){
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "createdDate",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    },
                    {
                        field: "createdDate",
                        operator: ">",
                        value: this.firstDateOfLastMonth.getTime(),
                        type: 'date'
                    }
                ]
            }
        ]
    }

    public getUsersComboOptions(){
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Este mes",
                id: 2,
                filters: [
                    {
                        field: "date",
                        operator: "<=",
                        value: this.today.getTime(),
                        type: 'date'
                    },
                    {
                        field: "date",
                        operator: ">=",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    }
                ]
            },
            {
                desc: "Mes anterior",
                id: 3,
                filters: [
                    {
                        field: "date",
                        operator: "<",
                        value: this.firstDateOfMonth.getTime(),
                        type: 'date'
                    },
                    {
                        field: "date",
                        operator: ">",
                        value: this.firstDateOfLastMonth.getTime(),
                        type: 'date'
                    }
                ]
            }
        ]
    }

    public getSavingspfComboOptions(){
        return [
            {
                desc: "Últimas (sin filtro)",
                id: 1,
                filters: []
            },
            {
                desc: "Tipo de Ahorro: PF",
                id: 2,
                filters: [
                    {
                        field: "type",
                        operator: '==',
                        value: "CD"
                    }
                ]
            },
            {
                desc: "Tipo de Ahorro: Ahorro",
                id: 3,
                filters: [
                    {
                        field: "type",
                        operator: "==",
                        value: "SAVINGS"
                    }
                ]
            }
        ]
    }

}