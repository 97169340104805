import { Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'miplataapp';
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }
   
}
