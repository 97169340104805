import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

    constructor( private recaptchaV3Service: ReCaptchaV3Service) { }
 
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.recaptchaV3Service.execute('login').pipe(
            take(1), // See https://stackoverflow.com/a/60196923/828547.
            switchMap((token) => {
                if (token) {
                    request = request.clone({
                        setHeaders: { "X-Validation-Token": token },
                    });
                }
                return next.handle(request);
            }),
        );
    }
}