import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { Iobjectwrapper } from '../interfaces/iobjectwrapper';
import { Inote } from '../interfaces/inote';
import { Iphoto } from '../interfaces/iphoto';
import { CustomHttpParamEncoder } from '../utils/custom-http-param-encoder';


export class BaseService<T> {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 
       'Accept': 'application/json'})
  };
  constructor(
    private httpClient: HttpClient,
    private url: string,
    private endpoint: string,
    private subresource: string) {
      
    }

    listAll(fieldAttr: string, data:string, keyLastEement?: string|null, filters?: Array<any>, caseSensitive=true): Observable<Iobjectwrapper<T>[]>{

      let params = new HttpParams();

      if(keyLastEement){
        params = params.append('id',keyLastEement);
      }

      if(fieldAttr !== undefined){
        params = params.append('field',fieldAttr);
      }

      if(caseSensitive !== undefined){
        params = params.append('caseSensitive',caseSensitive);
      }

      if(data !== undefined){
        params = params.append('data',data);
      }

      if(filters!==undefined){
        params = params.append('filters',JSON.stringify(filters));
      }
            
      return this.httpClient.get<Iapiresponse<Iobjectwrapper<T>[]>>(`${this.url}/${this.endpoint}`,  {params

      })
        .pipe(
          map(e=>e.result)
        );
    }

    getOne(key:string): Observable<Iobjectwrapper<T>>{
      
      return this.httpClient.get<Iapiresponse<Iobjectwrapper<T>>>(`${this.url}/${this.endpoint}/${key}`, this.httpOptions)
        .pipe(
          map(e=>{
            if (e.result.data['balances']){
              e.result.data['balances'].forEach((bal,index)=>{
                e.result.data['balances'][index]['amount'] = (e.result.data['balances'][index]['amount']/100).toFixed(2);
              })
            }
            
            return e.result;
          })
        );
    }

    updateOne(key: string, member:T):Observable<Iobjectwrapper<T>>{
      return this.httpClient.put<Iapiresponse<Iobjectwrapper<T>>>(`${this.url}/${this.endpoint}/${key}`, member,this.httpOptions)
      .pipe(
        map(e=>e.result)
      );
    }

    updateState(key: string, state:string,motive: string ):Observable<Iobjectwrapper<T>>{
      return this.httpClient.put<Iapiresponse<Iobjectwrapper<T>>>(`${this.url}/${this.endpoint}/${'state'}/${key}`, {"status":state, "motive":motive},this.httpOptions)
      .pipe(
        map(e=>e.result)
      );
    }

    listNotesAll(key:string, keyLastEement?: string, filters?: Array<any>,fieldAttr?: string, data?:string): Observable<Iobjectwrapper<Inote>[]>{
      let params = new HttpParams();
      // if (data) params = params.append('data', data);
      // if(fieldAttr) params = params.append('field', fieldAttr);
      if(keyLastEement)  params = params.append('id', keyLastEement);

      if(filters!==undefined){
        params = params.append('filters',JSON.stringify(filters));
      }

       return this.httpClient.get<Iapiresponse<Iobjectwrapper<Inote>[]>>(`${this.url}/${this.endpoint}/${key}/${'notesList'}`, {params
       })
         .pipe(
           map(e=>e.result)
         );
     }

     addNote(key:string,  note: Inote): Observable<Iobjectwrapper<Inote>>{
      
       return this.httpClient.post<Iapiresponse<Iobjectwrapper<Inote>>>(`${this.url}/${this.endpoint}/${key}/${'notes'}`, note ,this.httpOptions)
         .pipe(
           map(e=>e.result)
         );
     }

     //THIS METHOD IS NOT BEING USED DELETE AT THE END
     search(data: string){
      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      params = params.append('data', data);
      return this.httpClient.get<Iapiresponse<Iobjectwrapper<T>[]>>(`${this.url}/${this.endpoint}/${'search'}`, {params:params})
      .pipe(
        map(e=>e.result)
      );
     }

     listPhotosAll(key:string): Observable<Iobjectwrapper<T>>{
      
       return this.httpClient.get<Iapiresponse<Iobjectwrapper<T>>>(`${this.url}/${this.endpoint}/${key}/${'photos'}`, this.httpOptions)
         .pipe(
           map(e=>e.result)
         );
     }

     deletePhoto(key: string, photo: Iobjectwrapper<Iphoto>){
      return this.httpClient.delete(`${this.url}/${this.endpoint}/${key}/${'photos'}/${photo.data.type}`, this.httpOptions);
     }

     updatePhoto(key: string, photo: Iobjectwrapper<Iphoto>){
      return this.httpClient.put<Iapiresponse<Iobjectwrapper<Iphoto>>>(`${this.url}/${this.endpoint}/${key}/${'photos'}/${photo.data.type}`, photo.data,this.httpOptions)
      .pipe(
        map(e=>e.result)
      )
     }

     async getCount(body:any){
      let params = new HttpParams();

      if(body.filters!==undefined){
        params = params.append('filters',JSON.stringify(body.filters));
      }else{
        params = params.append('filters',JSON.stringify([]));
      }

      return await firstValueFrom(this.httpClient.get<Iapiresponse<number>>(`${this.url}/${this.endpoint}/${'count'}`, {params} )
      .pipe(
        map(e=>e.result)
      ))
     }

     addPhoto(file:any, key:string, type: string){
      const formData = new FormData();
      formData.append('image', file);
      formData.append('type',type )

     let  httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data'})
      };
  
       return this.httpClient.post<Iapiresponse<Iobjectwrapper<Iphoto>>>(`${this.url}/${this.endpoint}/${key}/${'photos'}`,formData)
       .pipe(
        map(e=>e.result)
      )
     }
}
