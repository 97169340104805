import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../auth/services';
import { SideNavItems, SideNavSection } from '../models';
import { NavigationService } from '../services';
import { Subscription } from 'rxjs';
import { TransactionService } from 'src/app/services/transaction.service';
import { Constants, MemberState, TxnType, TxnState } from 'src/app/utils/contants';
import { BusinessService } from 'src/app/services/business.service';
import { MemberService } from 'src/app/services/member.service';
@Component({
    selector: 'notifications',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './notifications.component.html',
    styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;


    ///

    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;
    public newBusinessesCount: number;
    public memberSuspendedCount: number;
    public memberClosedCount: number;
    public depositPendingCount: number;
    public withdrawalPendingCount: number;
    public spinner = { NewBusinessesCount: false, DepositPendingCount: false, WithdrawalPendingCount: false, MemberSuspendedCount: false, MemberClosedCount: false };

    constructor(private cdr: ChangeDetectorRef, private memberService: MemberService, private businessService: BusinessService, private transactionService: TransactionService, public userService: UserService, public navigationService: NavigationService) {
        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
        });

        this.changes.observe(<Element>this.element, {
            attributes: true
        });

        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        let endDate = new Date();
        endDate.setHours(23, 59, 59, 0);

        this.getDataNotification(startDate, endDate);
    }

    ngOnInit() {
        /*
        let startDate=new Date();
        startDate.setHours(0,0,0,0);
        let endDate=new Date();
        endDate.setHours(23,59,59,0);
        this.getData(startDate, endDate);*/
    }

    async getData(startDate: Date, endDate: Date) {

        this.spinner = { DepositPendingCount: true, MemberClosedCount: true, MemberSuspendedCount: true, NewBusinessesCount: true, WithdrawalPendingCount: true };

        this.newBusinessesCount = await this.businessService.getCount({
            filters: [
                {
                    field: "createdAt",
                    operator: ">",
                    value: startDate.getTime(),
                    type: "date"
                },
                {
                    field: "createdAt",
                    operator: "<",
                    value: endDate.getTime(),
                    type: "date"
                }
            ]
        });

        this.spinner.NewBusinessesCount = false

        this.memberSuspendedCount = await this.memberService.getCount({
            filters: [
                {
                    field: "updatedAt",
                    operator: ">",
                    value: startDate.getTime(),
                    type: "date"
                },
                {
                    field: "updatedAt",
                    operator: "<",
                    value: endDate.getTime(),
                    type: "date"
                },
                {
                    field: "status",
                    operator: "==",
                    value: MemberState.SUSPENDED,
                    type: "string"
                }
            ]
        });
        this.spinner.MemberSuspendedCount = false

        this.memberClosedCount = await this.memberService.getCount({
            filters: [
                {
                    field: "updatedAt",
                    operator: ">",
                    value: startDate.getTime(),
                    type: "date"
                },
                {
                    field: "updatedAt",
                    operator: "<",
                    value: endDate.getTime(),
                    type: "date"
                },
                {
                    field: "status",
                    operator: "==",
                    value: MemberState.CLOSED,
                    type: "string"
                }
            ]
        }); 
        this.spinner.MemberClosedCount = false

        this.depositPendingCount = await this.transactionService.getCount({
            filters: [
                {
                    field: "createdAt",
                    operator: ">",
                    value: startDate.getTime(),
                    type: "date"
                },
                {
                    field: "createdAt",
                    operator: "<",
                    value: endDate.getTime(),
                    type: "date"
                },
                {
                    field: "type",
                    operator: "==",
                    value: TxnType.DEPOSIT,
                    type: "string"
                },
                {
                    field: "status",
                    operator: "==",
                    value: TxnState.CREATED,
                    type: "string"
                }
            ]
        });
        this.spinner.DepositPendingCount = false

        this.withdrawalPendingCount = await this.transactionService.getCount({
            filters: [
                {
                    field: "createdAt",
                    operator: ">",
                    value: startDate.getTime(),
                    type: "date"
                },
                {
                    field: "createdAt",
                    operator: "<",
                    value: endDate.getTime(),
                    type: "date"
                },
                {
                    field: "type",
                    operator: "==",
                    value: TxnType.WITHDRAWAL,
                    type: "string"
                },
                {
                    field: "status",
                    operator: "==",
                    value: TxnState.CREATED,
                    type: "string"
                }
            ]
        });
        this.spinner.WithdrawalPendingCount = false

        this.cdr.detectChanges();
    }
    async getDataNotification(startDate: Date, endDate: Date) {

        this.spinner = { DepositPendingCount: true, MemberClosedCount: true, MemberSuspendedCount: true, NewBusinessesCount: true, WithdrawalPendingCount: true };

        let response = await this.transactionService.getCountNotification({
            filters: [
                { field: "createdAt", operator: ">", value: startDate.getTime(), type: "date" },
                { field: "createdAt", operator: "<", value: endDate.getTime(), type: "date" },
                { field: "updatedAt", operator: ">", value: startDate.getTime(), type: "date" },
                { field: "updatedAt", operator: "<", value: endDate.getTime(), type: "date" }]
        });

        if (response.BusinessesCount !== undefined) {

            Object.assign(this, response);

            this.spinner = { DepositPendingCount: false, MemberClosedCount: false, MemberSuspendedCount: false, NewBusinessesCount: false, WithdrawalPendingCount: false };

            this.cdr.detectChanges();
        }

    }
    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }
}
