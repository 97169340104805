import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Iobjectwrapper } from '../interfaces/iobjectwrapper';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { Itransaction } from '../interfaces/itransaction';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Iservice } from '../interfaces/iservice';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 
       'Accept': 'application/json'})
  };
  private url: string;
  private endpoint: string;

  
  constructor(private http: HttpClient) {
    this.url= environment.APIREST;
    this.endpoint="transactions";
   }

   listAllByType(type:string, fieldAttr?:string, data?:string, keyLastEement?: string, filters?: Array<any>): Observable<Iobjectwrapper<Itransaction>[]>{
    let params = new HttpParams();
    if(type !== undefined){
      params = params.append('type',type);
    }
    if(keyLastEement){
      params = params.append('id',keyLastEement);
    }

    if(fieldAttr !== undefined){
      params = params.append('field',fieldAttr);
    }

    if(data !== undefined){
      params = params.append('data',data);
    }

    if(filters!==undefined){
      params = params.append('filters',JSON.stringify(filters));
    }else{
      params =  params.append('filters',JSON.stringify([]));
    }
      
    return this.http.get<Iapiresponse<Iobjectwrapper<Itransaction>[]>>(`${this.url}/${this.endpoint}`, { 
      params
     })
      .pipe(
        map(e=>e.result)
      );
  }

  accept(key: string, body:any):Observable<Iobjectwrapper<Itransaction>>{
    return this.http.post<Iapiresponse<Iobjectwrapper<Itransaction>>>(`${this.url}/${this.endpoint}/${key}/${'accept'}`,body,this.httpOptions )
    .pipe(
      map(e=>e.result)
    )
  }

  decline(key: string, body:any):Observable<Iobjectwrapper<Itransaction>>{
    return this.http.post<Iapiresponse<Iobjectwrapper<Itransaction>>>(`${this.url}/${this.endpoint}/${key}/${'decline'}`,body,this.httpOptions )
    .pipe(
      map(e=>e.result)
    )
  }

  async getCount(body:any){
    
    let params = new HttpParams();

    if(body.filters.length > 0){
      params = params.append('filters',JSON.stringify(body.filters));
    }
    else{
      params = params.append('filters',JSON.stringify([]));
    }

    return await firstValueFrom(this.http.get<Iapiresponse<number>>(`${this.url}/${this.endpoint}/${'count'}`, {params})
    .pipe(
      map(e=>e.result)
    ))
   }

  get(key: string,params?):Observable<Iobjectwrapper<Itransaction>>{
    return this.http.get<Iapiresponse<Iobjectwrapper<Itransaction>>>(`${this.url}/${this.endpoint}/${key}`,{...this.httpOptions,params} )
    .pipe(
      map(e=>e.result)
    )
  }

  getCashFlow(startDate: Date, endDate: Date):Observable<{ transactionsGrouped:Array<{
    day: Date,
    txns: Array<Iobjectwrapper<Itransaction>>
    totalDeposits: number,
    totalWithdrawals:number,
    total:number
  }>}> {
    let params = new HttpParams();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    params = params.append('startDate',''+startDate.getTime() );
    params = params.append('endDate',''+endDate.getTime());
    return this.http.get<Iapiresponse<{ transactionsGrouped:Array<{
      day: Date,
      txns: Array<Iobjectwrapper<Itransaction>>
      totalDeposits: number,
      totalWithdrawals:number,
      total:number
    }>}>>(`${this.url}/${this.endpoint}/${'cashFlow'}`, {params:params} )
    .pipe(
      map(e=>e.result)
    )
  }

  async getCountUnique(body:any){
    try {
      let params = new HttpParams();

      if(body.filters.length > 0){
        params = params.append('filters',JSON.stringify(body.filters));
      } 
      else{
        params = params.append('filters',JSON.stringify([]));
      }
    
      let nameFunction = "countDashboard";
      return lastValueFrom(this.http.get<Iapiresponse<any>>(`${this.url}/${this.endpoint}/${nameFunction}`, {params})
      .pipe(
        map(e=>e.result)
      ));
    
    } catch (error) {
      
    }
    
    
   }

   async getCountNotification(body:any){
    try {
      let params = new HttpParams();

      if(body.filters.length > 0){
        params = params.append('filters',JSON.stringify(body.filters));
      } 
      else{
        params = params.append('filters',JSON.stringify([]));
      }
    
      let nameFunction = "getNotification";
      return lastValueFrom(this.http.get<Iapiresponse<any>>(`${this.url}/${this.endpoint}/${nameFunction}`, {params})
      .pipe(
        map(e=>e.result)
      ));
    
    } catch (error) {
      
    }
    
    
   }
  
}
