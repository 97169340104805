import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { navItems } from './../../_nav';
import { BusinessService } from 'src/app/services/business.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { MemberService } from 'src/app/services/member.service';
import { Constants, MemberState, TxnType, TxnState } from 'src/app/utils/contants';
import { TransactionService } from 'src/app/services/transaction.service';
import { sideNavItems, sideNavSections } from '../data';
import { UserService } from '../auth/services';
import { Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
@Component({
  selector: 'default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent  implements OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public newBusinessesCount: number;
  public memberSuspendedCount: number;
  public memberClosedCount: number;
  public depositPendingCount: number;
  public withdrawalPendingCount:number;
  public spinnerNewBusinessesCount=false;
  public spinnerDepositPendingCount=false;
  public spinnerWithdrawalPendingCount=false;
  public spinnerMemberSuspendedCount=false;
  public spinnerMemberClosedCount=false;

  showMenu = false;
  showNotifications = false;
  currentRoute = '';

  
  sideNavItems = sideNavItems;
  sideNavSections = sideNavSections;
  sidenavStyle = 'sb-sidenav-light';

  @Input() static = false;
    @Input() light = false;
    @HostBinding('class.sb-sidenav-toggled') sideNavHidden = false;
    @HostBinding('class.sb-sidenav-toggled-right') sideNavHiddenRight = false;
    subscription: Subscription = new Subscription();
    // sideNavItems = sideNavItems;
    // sideNavSections = sideNavSections;
    // sidenavStyle = 'sb-sidenav-dark';
  
  constructor(private changeDetectorRef: ChangeDetectorRef, private navigationService: NavigationService, private userService: UserService,private router: Router, private transactionService: TransactionService, private memberService: MemberService, private businessService: BusinessService,private route: Router, private authService: AuthenticationService) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          let url = router.url
          if(url !=='/'){
            url = url[1].toUpperCase() + url.slice(2);
          }
          this.currentRoute = url;
        }
      }
    );

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    
  }


  ngOnInit() {
    
    if (this.light) {
        this.sidenavStyle = 'sb-sidenav-dark';
    }
    this.subscription.add(
        this.navigationService.sideNavVisible$().subscribe(isVisible => {
            this.sideNavHidden = !isVisible;
            this.changeDetectorRef.markForCheck();
        })
    );

    this.subscription.add(
      this.navigationService.sideNavVisibleRight$().subscribe(isVisible => {
          this.sideNavHiddenRight = !isVisible;
          this.changeDetectorRef.markForCheck();
      })
  );
}

  toggleNotifications(){
    this.showNotifications = !this.showNotifications;
  }

  logout(){
    this.authService.logout().subscribe(data=>{
      this.route.navigate(['/login']);
    })
  }
}
