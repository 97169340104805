import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route, UrlSegment } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InfoDialogComponent } from '../info.component';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  
  constructor(private _authService: AuthenticationService, private _router: Router, private modalService: BsModalService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authService.hasPermission(next.data.permissions);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._authService.hasPermission(next.data.permissions);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.hasPermission(route.data.permissions)
    .pipe(map(res=>{
      if(!res) {
        this.showUnauthorizeMessage();
        return res;
      }
      else return res;
    }));

  }

  showUnauthorizeMessage(){
    const modal = this.modalService.show(InfoDialogComponent, { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
            modal.content.message="No tiene permiso suficiente para realizar esta acción."
            
  }
  
}
