// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(74, 74, 74, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/views/shared/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AACE;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,uBAAA;EACA,+CAAA;EACA,sCAAA;AAEJ;;AAAE;EACE;IACE,oBAAA;IACA,iEAAA;EAGJ;EADE;IACE,yBAAA;IACA,8DAAA;EAGJ;EADE;IACE,0BAAA;EAGJ;AACF;AAAE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".lds-hourglass {\n    display: inline-block;\n    position: relative;\n    width: 80px;\n    height: 80px;\n  }\n  .lds-hourglass:after {\n    content: \" \";\n    display: block;\n    border-radius: 50%;\n    width: 0;\n    height: 0;\n    margin: 8px;\n    box-sizing: border-box;\n    border: 32px solid #fff;\n    border-color: #fff transparent #fff transparent;\n    animation: lds-hourglass 1.2s infinite;\n  }\n  @keyframes lds-hourglass {\n    0% {\n      transform: rotate(0);\n      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);\n    }\n    50% {\n      transform: rotate(900deg);\n      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n    }\n    100% {\n      transform: rotate(1800deg);\n    }\n  }\n\n  .overlay{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(74, 74, 74, .8);\n    z-index: 10;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n   \n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
