import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Auth as AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { map, catchError, share } from 'rxjs/operators';
import { throwError, of, Observable } from 'rxjs';
import { Constants } from '../utils/contants';
import { ConfirmationService } from '../views/shared/confirmation/confirmation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private headers;
  private api = environment.APIREST;
  private bank = environment.BANK_NAME;
  private rootPath = "auth";
  private login_path = "login";
  private mfasend_path = "send2mfa";
  private mfaverify_path = "verify2mfa";
  private change_password_path = "changePassword";
  private isLoggedIn = "isLoggedIn";
  private logout_path = "logout";
  private forgetPassword = "forgotPassword";
  private meInfo_path = "me";
  private ADMIN_PERMISION = "ADMIN";
  user: Observable<any>;
  private permissions;
  constructor(private afAuth: AngularFireAuth, private http: HttpClient) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json'); this.headers = headers
  }

  isAuthenticated() {
    //return true;

    return this.http.get<any>(`${this.api}/${this.rootPath}/${this.isLoggedIn}`, { headers: this.headers })
  }

  login(username: string, password: string, bankId: string): Observable<any> {

    //return this.afAuth.auth.signInWithEmailAndPassword(username, password)
    return this.http.post<any>(`${this.api}/${this.rootPath}/${this.login_path}`, {
      email: username,
      password: password
    },
      {
        headers: { bank: bankId }
      })
      .pipe(
        map(e => { return { code: "200", accessToken: e.accessToken } }),
        catchError(err => {

          let resultString = Constants.loginErrorCodesList.find(e => e.code == err.error.code).desc;
          return of({ code: err.error.code, desc: resultString })
        })
      );
  }

  forgotPassword(username: string): Observable<any> {

    //return this.afAuth.auth.signInWithEmailAndPassword(username, password)
    return this.http.post<any>(`${this.api}/${this.rootPath}/${this.forgetPassword}`, {
      email: username,
    })
      .pipe(
        map(e => { return { code: "200", message: e.result } }),
        catchError(err => {

          let resultString = Constants.loginErrorCodesList.find(e => e.code == err.error.code).desc;
          return of({ code: err.error.code, desc: resultString })
        })
      );
  }

  send2MfaToken() {
    return this.http.post<{}>(`${this.api}/${this.rootPath}/${this.mfasend_path}`, {}, { headers: this.headers })
  }


  verify2Factor(token: string) {
    return this.http.post<any>(`${this.api}/${this.rootPath}/${this.mfaverify_path}`, {
      token: token
    }, { headers: this.headers })
  }

  changePassword(data) {
    return this.http.post<any>(`${this.api}/${this.rootPath}/${this.change_password_path}`, data, { headers: this.headers });
  }

  logout() {
    return this.http.post<any>(`${this.api}/${this.rootPath}/${this.logout_path}`, {}, { headers: this.headers }).pipe(
      map(e => sessionStorage.clear())
    )


  }


  meInfo() {
    //return true;

    return this.http.get<any>(`${this.api}/${this.rootPath}/${this.meInfo_path}`, { headers: this.headers })
  }

  getInfo() {
    if (this.user) {
      return this.user;
    } else {
      this.user = this.http.get<any>(`${this.api}/${this.rootPath}/${this.meInfo_path}`, { headers: this.headers }).pipe(share());
      return this.user;
    }
  }

  hasPermission(permissions: Array<string>): Observable<boolean> {

    return this.meInfo().pipe(

      map(e => {
        let userPermissions: Array<any>;
        userPermissions = e.result.permissions[0];

        let headerPermissions = Object.keys(userPermissions);

        let response
        const existePermiso = permissions.some((permission) => {
          response = Object.keys(userPermissions).includes(permission) && (userPermissions[permission] === true || userPermissions[permission].read);
        });

        return response;
      })

    )
  }
  isLogged() {
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return token != null;

  }

  hasActions(menu: string, actions: Array<string>): Observable<boolean> {

    return this.meInfo().pipe(

      map(e => {
        let userActions: Array<any>;
        userActions = e.result.actions;
        return userActions.some(r => userActions.includes(r))
      })

    )
  }

  public setPermissions(permissions){
    this.permissions = permissions;
  }
  public getPermissions(){
    return this.permissions;
  }
}
