import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserService } from '../auth/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
@Component({
    selector: 'sb-top-nav-user',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {
    constructor(public userService: UserService, private authService: AuthenticationService, private route: Router) {}
    ngOnInit() {}

    logout(){
        this.authService.logout().subscribe(data=>{
            this.route.navigate(['/login']);
          })
    }
}
