import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, of, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfirmationService } from '../views/shared/confirmation/confirmation.service';
import { AuthenticationService } from '../services/authentication.service';
import { TouchSequence } from 'selenium-webdriver';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InfoDialogComponent } from '../info.component';
import { Router } from '@angular/router';
import { SessionInfoComponent } from '../sessioninfo.component';
import { ErrorInfoComponent } from '../errorinfo.component';
import jwt_decode from "jwt-decode";
import  {UsersService}  from 'src/app/services/users.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    private modal : BsModalRef;

    constructor( public modalService: BsModalService, private authService: AuthenticationService, private _router: Router) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = sessionStorage.getItem('token');
        
        if (token) {
            var decoded = jwt_decode(token);
            let permissions = JSON.parse(decoded['claims']['permissions']);
            this.authService.setPermissions(permissions[0])
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    
                }
                return event;
            }),
            catchError((err, caugth) => {
                if (!request.url.includes("auth") && !request.url.includes("banks") && this.modal==null) {
                    if (err.status == 401) {
                        this.showUnauthorizeMessage();
                        return empty();
                        //return of(new HttpResponse({ body: {} }));
                    }else {
                         this.showUnexpectedErrorMessage(err.error);   
                         return empty();
                         //return of(new HttpResponse({ body: {} }));
                    }
                }
                this._router.navigateByUrl('/login');
                return throwError(err);

            }));
    }

    showUnauthorizeMessage() {
        this.modal = this.modalService.show(SessionInfoComponent, {class: 'modal-dialog-centered', animated: true, keyboard: true, backdrop: 'static', ignoreBackdropClick: false });
        this.modal.content.error=false
        this.modal.content.auth = true;
        this.modal.content.subject.subscribe(data => {
            sessionStorage.clear();
            this._router.navigate(['/login']);
            this.modal=null;
        })

    }

    showUnexpectedErrorMessage(err:{code:string, description:string}){
        let message = this.resolveErrorMessage(err);
        this.modal = this.modalService.show(ErrorInfoComponent, {class: 'modal-dialog-centered', animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
        this.modal.content.auth=false;
        this.modal.content.error =true;
        this.modal.content.message = message;
        this.modal.content.subject.subscribe(data=>{
            this.modal=null;
        })
    }

    resolveErrorMessage(err:{code:string, description:string}):string{
        if(err.code==null) return "Hubo un error inesperado. Intente nuevamente."
        if(err.code.includes("OMG") || err.code.includes("BOFF_PLATIA")){
            return err.description;
        }else return "Hubo un error inesperado. Intente nuevamente."
    }
}