import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { Ibank } from '../interfaces/ibank';
import { Ibusiness } from '../interfaces/ibusiness';
import { Imember } from '../interfaces/imember';
import { Imemberdetails } from '../interfaces/imemberdetails';
import { Imemberfamily } from '../interfaces/imemberfamily';
import { Imembertrx } from '../interfaces/imembertrx';
import { Iobjectwrapper } from '../interfaces/iobjectwrapper';
import { BaseService } from './base.service';



@Injectable({
  providedIn: 'root'
})
export class MemberService extends BaseService<Imember>{
  bankTokens: Array<Iobjectwrapper<Ibank>>;
  constructor(httpClient: HttpClient, private httpClient2: HttpClient) {
    super(httpClient, environment.APIREST, 'members', 'photos');

    this.getInfoBank().subscribe(data => {

      this.bankTokens = data.data;
    })
  }

  public completeFiliation(id: string, completeFiliation: Boolean, kycStatus: string) {
    return this.httpClient2.post<Iapiresponse<Boolean>>(`${environment.APIREST}/${'members'}/${'completeFiliation'}/${id}`, { kycComplete: completeFiliation, kycStatus: kycStatus }, this.httpOptions)
      .pipe(
        map(e => e.result)
      );
  }

  public updateInteropStatus(id: string, status: string, motive: string) {
    return this.httpClient2.put<Iapiresponse<Boolean>>(`${environment.APIREST}/${'members'}/${id}/interopState`, { status: status, motive: motive }, this.httpOptions).pipe(
      map(e => e.result)
    );
  }
  
  public refreshInteropState(id: string) {
    return this.httpClient2.put<Iapiresponse<Boolean>>(`${environment.APIREST}/${'members'}/${id}/refreshInteropstate`, {}, this.httpOptions).pipe(
      map(e => e.result)
    );
  }

  public resetPassword(id: string, motive: string) {
    return this.httpClient2.post<Iapiresponse<Boolean>>(`${environment.APIREST}/${'members'}/${'resetPassword'}/${id}`, { "motive": motive }, this.httpOptions)
      .pipe(
        map(e => e.result)
      );
  }

  getFamily(key: string): Observable<Iobjectwrapper<Array<Iobjectwrapper<Imemberfamily>>>> {
    return this.httpClient2.get<Iapiresponse<Iobjectwrapper<Array<Iobjectwrapper<Imemberfamily>>>>>(`${environment.APIREST}/${'members'}/${key}/${'family'}`, this.httpOptions)
      .pipe(
        map(e => e.result)
      );
  }

  getBusinesses(key: string): Observable<Iobjectwrapper<Array<Iobjectwrapper<Ibusiness>>>> {
    return this.httpClient2.get<Iapiresponse<Iobjectwrapper<Array<Iobjectwrapper<Ibusiness>>>>>(`${environment.APIREST}/${'members'}/${key}/${'businesses'}`, this.httpOptions)
      .pipe(
        map(e => e.result)
      );
  }

  getTransactions(key: string, page: string, perpage: string, filters: string,defaultPath='membertransactions'): Observable<Iobjectwrapper<Array<Iobjectwrapper<Imembertrx>>>> {
    let params = new HttpParams();

    params = params.append("page", page);
    params = params.append("perpage", perpage);
    if (filters.length > 0)
      params = params.append("filters", filters);
    else
      params = params.append("filters", "");
    return this.httpClient2.get<Iapiresponse<Iobjectwrapper<Array<Iobjectwrapper<Imembertrx>>>>>(`${environment.APIREST}/${'members'}/${key}/${defaultPath}` , {params: params})
      .pipe(
        map(e => {
          if (e.result && e.result.data && e.result.data.length > 0){


            e.result.data = e.result.data.filter(t => t['metadata']['type']!=='HOLD')


            e.result.data.forEach(async (trans, index) => {
              e.result.data[index] = await this.modifyMessage(e.result.data[index]);
            })
          }
          return e.result;
        })
      );

  }

  getInfoBank() {
    return this.httpClient2.get<Iapiresponse<Iobjectwrapper<Array<Iobjectwrapper<Ibank>>>>>(`${environment.APIREST}/${'members'}/${'infoToken'}`, this.httpOptions)
      .pipe(

        map(e => e.result)
      );
  }

  /**
   * Get the message for positive and negative transactions
   * @param trans
   * @param message
   * @param direction
   * @returns
   */
  async getMessage(trans, message, direction) {
    let myOwnAccount=false;

    if (direction) {
      message = "Recibido de";
      trans["idAddress"] = trans["from"]["address"]

      if (trans["from"]['token_id'] === trans["to"]['token_id']){

        if( !trans["from"]["user"]['id'] && trans["to"]["user"]['id'] && trans["object"] == "transaction" && trans["metadata"]['concept']){
          message = trans["metadata"]['concept']
        }else if (!trans["from"]["user"]['id'] && trans["to"]["user"]['id'] && trans["object"] == "transaction"){
          message = " Recarga o abono";
        }
        else{
          message = message + " cuenta propia"
        }
        myOwnAccount=true;

        if (trans["from"]["user"]['metadata']['firstLastName'] && trans["from"]["user"]['metadata']['name']) message =  trans["from"]["user"]['metadata']['name'] + ' ' + trans["from"]["user"]['metadata']['firstLastName'] + '';

      } ;

    }
    else {
      message = "Enviado ";
      trans["idAddress"] = trans["to"]["address"];

      if (trans["from"]['token_id'] === trans["to"]['token_id']) {
        myOwnAccount=true;message = message + " a mi cuenta";//textToDisplay = "De cuenta propia";

        if( trans["from"]["user"]['id'] && !trans["to"]["user"]['id'] && trans["object"] == "transaction"){

          message = trans["metadata"]['concept']
        }else{
          if (trans["to"]["user"]['metadata']['name'] && trans["to"]["user"]['metadata']['firstLastName']) message = trans["to"]["user"]['metadata']['name'] + '-' + trans["to"]["user"]['metadata']['firstLastName'];
        }

        if(trans["to"]["user"]['metadata'] && !trans["to"]["user"]['metadata']['name'] && !trans['metadata']['type']){
          if (trans['metadata']['concept'])
            message = trans['metadata']['concept'];
          else{
            message = 'Anulación o Retención';
          }


        }

      }else{

        if( trans["metadata"]["type"] =="USER" || trans["metadata"]["type"] =="HOLD" ){
          message=trans["metadata"]["concept"]
        }

      }

    }
    return message;
  }


  async modifyMessage(obj) {

    let recibido = obj["to"]["amount"] > 0;
    let toToken = obj["to"]["token"]["symbol"];
    let interno = false;
    let message = "";
    let symbolMoney = "";

    if (toToken == this.bankTokens["shareTokenSymbol"]) {
      message = "Movido a cuenta de aportes";
      interno = true;

    } else {
      if (obj["to"]["user"]["provider_user_id"] != null && obj["from"]["user"]["provider_user_id"] != null && obj["to"]["user"]["provider_user_id"] == obj["from"]["user"]["provider_user_id"]) {
        switch (toToken) {
          case this.bankTokens["savingsTokenSymbol"]: {
            message = "Interna: Billetera a Ahorros";
            break;
          }
          case this.bankTokens["walletTokenSymbol"]: {
            message = "Interna: Ahorros a Billetera";
            break;
          }
          default: {
            message = "A/de cuenta propia";
            break;
          }
        }
        interno = true;
      }
      else {

        if (recibido) {
          obj["idAddress"] = obj["from"]["address"];
          message = await this.getMessage(obj, message, recibido)
        } else {
          obj["idAddress"] = obj["to"]["address"];
          message = await this.getMessage(obj, message, recibido)
        }
      }
    }

    if (obj["metadata"]["type"] == "ADMIN"){
      if (!recibido)
        message = "Retiro";
      else
        message = "Depósito";
    }
    obj["message"] = message;

    //2.  set symbol money
    if (interno){
      symbolMoney = "<> S/";
    }
    else{
      symbolMoney = obj["to"]["amount"]>0? "S/ ":  "S/ -";
    }

    obj["symbolMoney"] = symbolMoney;
    //4. format amount
    obj["amount_format"] =  Math.abs( obj["to"]["amount"]) ;
    obj['message'] = message;
    return obj

  }

  getDetails(key: string): Observable<Iobjectwrapper<Imemberdetails>> {
    return this.httpClient2.get<Iapiresponse<Iobjectwrapper<Imemberdetails>>>(`${environment.APIREST}/${'members'}/${key}/details`, this.httpOptions)
      .pipe(
        map(e => {
          if (e.result) {
            e.result = e.result;
          }
          return e.result;
        })
      );
  }

}
