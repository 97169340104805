// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-custom {
  background-color: #163A5F;
}

.navbar-brand .logo-image {
  margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/top-nav/top-nav.component.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":["@import 'src/styles/variables.scss';\n.bg-custom {\n    background-color: #163A5F;\n}\n\n.navbar-brand .logo-image {\n    margin-left: 30px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
