import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'login-reset',
  styleUrls: ['login-reset.component.css'],
  templateUrl: 'login-reset.component.html'
})
export class LoginResetComponent {
  newPassword: string;
  verifyPassword: string;
  oobCode: string;
  mode: string;
  apikey: string;
  errorMessage: string;
  successMessage: string;
  resetPasswordForm = new FormGroup({
    oobCode: new FormControl(),
    mode: new FormControl(),
    captcha: new FormControl(),
    apikey: new FormControl(),
    password: new FormControl('New Password1', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&])(?=[^A-Z]*[A-Z]).{8,30}$/)
    ]),
    verifyPassword: new FormControl('Verify Password1', [Validators.required, Validators.minLength(8)]),
  });

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, public authService: AuthenticationService) {}

  ngOnInit() {
    sessionStorage.clear(); //si carga otra vez se borrar la variable
    this.resetPasswordForm.setValue(
      {
        oobCode: this.activatedRoute.snapshot.queryParamMap.get('oobCode'),
        mode: this.activatedRoute.snapshot.queryParamMap.get('mode'),
        apikey: this.activatedRoute.snapshot.queryParamMap.get('apiKey'),
        password: '',
        captcha:environment.RECAPTCHA_V3_SITE_KEY,
        verifyPassword: ''
      });
  }

  async changePassword() {
    try{
      let res = await firstValueFrom(this.authService.changePassword(this.resetPasswordForm.value))
      this.successMessage = "Su contraseña se ha cambiado con éxito!";
    }catch(e){
      this.errorMessage = "Error al actualizar password, por favor vuelva a intentarlo";
    }
  }

  onForgotPassword(){
    this.router.navigate(['login']);
  }

  containsNumbers(str) {
    return /\d/.test(str);
  }

  containsUppercase(str) {
    return /(?=.*[A-Z])/.test(str);
  }

  containsLowercase(str) {
    return /(?=.*[a-z])/.test(str);
  }

  containsMinLength(str, min) {
    return str.length >= min
  }

  containsSpecialChars(str) {
    return /(?=.*[-+_!@#$%^&*.,?])/.test(str);
  }
}
