import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { LoginResetComponent } from './views/login-reset/login-reset.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { MfaComponent } from './views/mfa/mfa/mfa.component';
import { PermissionGuard } from './guards/permission.guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'reset',
    component: LoginResetComponent
  },
  // {
  //   path: 'token',
  //   component: MfaComponent,
  //   data: {
  //     title: 'Token'
  //   }
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['dashboard']}
      },
      {
        path: 'depositos',
        loadChildren: () => import('./views/deposits/deposits.module').then(m => m.DepositsModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['depositos']} //, 'admin'
      },
      {
        path: 'retiros',
        loadChildren: () => import('./views/withdrawals/withdrawals.module').then(m => m.WithdrawalsModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['retiros']} //, 'admin'
      },
      {
        path: 'socios',
        loadChildren: () => import('./views/members/members.module').then(m => m.MembersModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['socios']} //,'admin'
      },
      {
        path: 'negocios',
        loadChildren: () => import('./views/businesses/businesses.module').then(m => m.BusinessesModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['negocios']} //, 'admin'
      },
      {
        path: 'prestamos',
        loadChildren: () => import('./views/loans/loans.module').then(m => m.LoansModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['prestamos']} //,'admin'
      },
      {
        path: 'cargos',
        loadChildren: () => import('./views/charges/charges.module').then(m => m.ChargesModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['cargos']} //, 'admin'
      },
      {
        path: 'ahorros',
        loadChildren: () => import('./views/savings/savings.module').then(m => m.SavingsModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['ahorros']} //, 'admin'
      },
      {
        path: 'plazofijos',
        loadChildren: () => import('./views/certificates/certificates.module').then(m => m.CertificatesModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['ahorros']} //, 'admin'
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['usuarios']} //, 'admin'
      },
      {
        path: 'contabilidad',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['usuarios']} //, 'admin'
      },
      {
        path: 'parametros',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['usuarios']} //, 'admin'
      },
      {
        path: 'conciliacion',
        loadChildren: () => import('./views/conciliation/conciliation.module').then(m => m.ConciliationModule),
        canLoad:[PermissionGuard],
        data: {permissions: ['admin']} //,'admin' era servicios 
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
