import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
// import { CardModule, DropdownModule, OffcanvasModule, CollapseModule, HeaderModule, SidebarModule, BreadcrumbModule,FooterModule, NavbarModule,NavModule,GridModule} from '@coreui/angular';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar-portable';
// Import 3rd party components
import { NgChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
// Import routing module
import { AppRoutingModule } from './app.routing';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];
// Import containers
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEsPE from '@angular/common/locales/es-PE';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from "@angular/fire/compat";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { DefaultLayoutComponent } from './containers';
import { UserService } from './containers/auth/services/user.service';
import { FooterComponent } from './containers/footer/footer.component';
import { NotificationsComponent } from './containers/notifications/notifications.component';
import { NavigationService } from './containers/services/navigation.service';
import { SideNavItemComponent } from './containers/side-nav-item/side-nav-item.component';
import { SideNavComponent } from './containers/side-nav/side-nav.component';
import { TopNavUserComponent } from './containers/top-nav-user/top-nav-user.component';
import { TopNavComponent } from './containers/top-nav/top-nav.component';
import { MatchheightDirective } from './directives/matchheight.directive';
import { ErrorInfoComponent } from './errorinfo.component';
import { IconsModule } from './icons/icons.module';
import { InfoDialogComponent } from './info.component';
import { CustomInterceptor } from './interceptors/custom.interceptor';
import { RecaptchaInterceptor } from './interceptors/recaptcha.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { SessionInfoComponent } from './sessioninfo.component';
import { LoginResetComponent } from './views/login-reset/login-reset.component';
import { LoginComponent } from './views/login/login.component';
import { MfaComponent } from './views/mfa/mfa/mfa.component';
import { SpinnerModule } from './views/shared/components/spinner/spinner.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const thirdParty = [IconsModule, NgbModule];

registerLocaleData(localeEsPE, 'es-PE');

@NgModule({
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        LoginComponent,
        LoginResetComponent,
        MfaComponent,
        MatchheightDirective,
        InfoDialogComponent,
        ErrorInfoComponent,
        SessionInfoComponent,
        SideNavComponent,
        NotificationsComponent,
        TopNavComponent,
        TopNavUserComponent,
        FooterComponent,
        SideNavItemComponent
    ],
    imports: [
        ...thirdParty,
        BrowserAnimationsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ModalModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        FormsModule,ReactiveFormsModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        NgChartsModule,
        NgxSpinnerModule,
        AngularFireModule,
        HttpClientModule,
        FontAwesomeModule,
        RecaptchaV3Module,
        SpinnerModule
    ],
    providers: [DatePipe, BsModalService, UserService,NavigationService,
        { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true, deps: [BsModalService, AuthenticationService, Router] },
        { provide: LOCALE_ID, useValue: "es-PE" },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_SITE_KEY},
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }],
    bootstrap: [AppComponent],
    exports: [...thirdParty]
})
export class AppModule {}
