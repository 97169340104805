import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, map} from 'rxjs/operators';
import { ConfirmationService } from '../views/shared/confirmation/confirmation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InfoDialogComponent } from '../info.component';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {

  constructor(private _authService: AuthenticationService, private _router: Router, private modalService: BsModalService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
//1) Usuario logeado?
let rpta = this._authService.isLogged();
if (!rpta){
  this._authService.logout();
  this._router.navigate(['/login']);
  return false;

}

    //2) VERIFICAR SI EL TOKEN NO HA EXPIRADO
// angular 13 const helper = new JwtHelperService();
let token = sessionStorage.getItem(environment.TOKEN_NAME);

  //3) VERIFICAR SI TIENES EL ROL NECESARIO PARA ACCEDER A ESE COMPONENTE 'PAGINA'

      return this._authService.isAuthenticated().pipe(
        map(e=>{
          if(e.result){
            return true;
          }
          else{
            const subject = new Subject<boolean>();
            
            if(sessionStorage.getItem("token")!=null)this.showUnauthorizeMessage();
            this._router.navigate(['/login']);
            return false;
          }
          
        }),
        catchError(err=>{
          const subject = new Subject<boolean>();
          
          if(sessionStorage.getItem("token")!=null)this.showUnauthorizeMessage();
          this._authService.logout().subscribe();
          this._router.navigate(['/login']);
          return of(false);
        })
      )

  }

  showUnauthorizeMessage(){
    const modal = this.modalService.show(InfoDialogComponent, { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
            modal.content.message="No existe ninguna sesión iniciada. Inicie sesión nuevamente."
            
  }
}
