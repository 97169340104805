import { Component, Input,EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-session-leave',
  templateUrl: './sessioninfo.component.html',
})
export class SessionInfoComponent {

  @Output() subject = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.emit(value);
  }
}