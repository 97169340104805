// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-refresh:hover {
  color: #167495 !important;
  text-decoration: underline !important;
}

.notifications-refresh {
  color: #20a8d8 !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #cnotifications {
    max-width: 80px;
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  #cnotifications {
    max-width: 200px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #cnotifications {
    max-width: 250px;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #cnotifications {
    max-width: 300px;
  }
}
/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
  #cnotifications {
    max-width: 300px;
  }
}
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/
/* Set width to make card deck cards 100% width */`, "",{"version":3,"sources":["webpack://./src/app/containers/default-layout/default-layout.component.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,qCAAA;AADJ;;AAIA;EACI,yBAAA;AADJ;;AAKA,mDAAA;AACA;EACI;IACI,eAAA;EAFN;AACF;AAKA,wFAAA;AACA;EACI;IACI,gBAAA;EAHN;AACF;AAMA,2CAAA;AACA;EACI;IACI,gBAAA;EAJN;AACF;AAOA,wDAAA;AACA;EACI;IACI,gBAAA;EALN;AACF;AAQA,oEAAA;AACA;EACI;IACI,gBAAA;EANN;AACF;AASA;;;CAAA;AAKA,iDAAA","sourcesContent":["@import 'src/styles/variables.scss';\n\n.notifications-refresh:hover {\n    color: #167495 !important;\n    text-decoration: underline !important;\n}\n\n.notifications-refresh{\n    color: #20a8d8 !important;\n}\n \n \n/* Small devices (landscape phones, 576px and up) */\n@media (min-width: 576px) {  \n    #cnotifications {\n        max-width:80px;\n    }\n}\n \n/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */\n@media (min-width: 768px) {  \n    #cnotifications {\n        max-width:200px;\n    }\n}\n \n/* Large devices (desktops, 992px and up) */\n@media (min-width: 992px) { \n    #cnotifications {\n        max-width:250px;\n    }\n}\n \n/* Extra large devices (large desktops, 1200px and up) */\n@media (min-width: 1200px) {  \n    #cnotifications {\n        max-width:300px;\n    }\n}\n \n/* Extra extra large devices (extra large desktops, 1400px and up) */\n@media (min-width: 1400px) {  \n    #cnotifications {\n        max-width:300px;\n    }\n}\n \n/*\n::::::::::::::::::::::::::::::::::::::::::::::::::::\nCustom media queries\n*/\n \n/* Set width to make card deck cards 100% width */\n@media (max-width: 950px) { \n \n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
