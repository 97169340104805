import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Route, Router } from '@angular/router';
import { TouchSequence } from 'selenium-webdriver';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InfoDialogComponent } from '../../info.component';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/services/util.service';
import { ErrorInfoComponent } from 'src/app/errorinfo.component';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['login.component.css'],
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  username:string;
  password: string;
  bank: string;
  result: any;
  loginFlag=true;
  secondFactorFlag=false;
  forgetPasswordFlag=false;
  token="";
  forgetPassword:string;
  secondFactorerror=null;
  forgetPasswordError=null;
  comboBank = [];
  private modal : BsModalRef;
  private subscription: Subscription = new Subscription();
  constructor(private recaptchaV3Service: ReCaptchaV3Service, private spinner: NgxSpinnerService, public authService: AuthenticationService, private modalService: BsModalService, private router: Router, private utilService: UtilService){}

  ngOnInit() {
    sessionStorage.clear(); //si carga otra vez se borrar la variable
    this.comboBank = this.utilService.getBanks();
  }

  showUnexpectedErrorMessageWhenLoadingBanks(){
    this.modal = this.modalService.show(ErrorInfoComponent, {class: 'modal-dialog-centered',  animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
    this.modal.content.auth=false;
    this.modal.content.error =true;
    this.modal.content.message = 'Error al cargar los bancos. Recargue la página. Si el incoveniente persiste, contacte al administrador.';
    this.modal.content.subject.subscribe(data=>{
        this.modal=null;
    })
}

  verify2Factor(){
    this.spinner.show()
    this.subscription.add(
      this.authService.verify2Factor(this.token).subscribe(data=>{
        
        if (data.result == "Segundo factor validado con exito"){
          
            sessionStorage.setItem(environment.TOKEN_NAME, this.token);
            
            let permissions = this.authService.getPermissions();

            let defaultRoute = '/dashboard';

            if(permissions.dashboard === true || permissions.dashboard?.read === true ){
              this.router.navigate([defaultRoute]);
            }else{
              let links = ['depositos','retiros','socios','negocios','ahorros','usuarios'];
              links.some( item => {
                defaultRoute = "/"+item;
                return (permissions[item]===true || permissions[item]?.read ===true);
              });
              this.router.navigate([defaultRoute]);
            }
        }
        
      }, err=>{
      
      this.spinner.hide()
      this.secondFactorerror=err.error.result;
      }, ()=>this.spinner.hide())
    )
  }

  login(){
    this.spinner.show()
    this.subscription.add(
      this.authService.login(this.username, this.password, this.bank).subscribe(data=>{
        this.result=data;
        
        if(data.code=="200"){
          this.result=null;
          sessionStorage.setItem("token", data.accessToken)
          sessionStorage.setItem("username",this.username)
          this.loginFlag=false;
          this.secondFactorFlag=true;
          this.spinner.show()
          this.subscription.add(
            this.authService.send2MfaToken().subscribe()
          )
        }
      }, error=>{}, ()=>this.spinner.hide())
    )
  }

  send2MfaToken(){
    this.subscription.add(
      this.authService.send2MfaToken().subscribe()
    )
  }

  resend2MfaToken(){
    this.subscription.add(this.authService.send2MfaToken().subscribe())
    const modal = this.modalService.show(InfoDialogComponent, { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
    modal.content.message="Token fue enviado";
        
  }

  onForgotPassword(){
    
    this.loginFlag=false;
    this.forgetPasswordError=null;
    this.forgetPassword='';
    this.forgetPasswordFlag=true;
  
  }

  sendForgetPasswordEmail(){
    
    this.spinner.show()
    this.subscription.add(
      this.authService.forgotPassword(this.forgetPassword).subscribe(data=>{
        
        if(data.code=="200"){
          const modal = this.modalService.show(InfoDialogComponent, { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });
          modal.content.message=data.message;
          modal.content.subject.subscribe(data=>{
            this.backToLoginPage();
          })
        } 
        this.forgetPasswordError=data.desc; 
      }, error=>{}, ()=>this.spinner.hide())
    )
  }

  backToLoginPage(){
    this.secondFactorFlag=false;
    this.forgetPasswordFlag=false
    this.loginFlag=true;
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
 }
