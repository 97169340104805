export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    title: true,
    name: 'Operaciones'
  },
  {
    name: 'Depósitos',
    url: '/depositos',
    icon: 'icon-plus'
  },
  {
    name: 'Retiros',
    url: '/retiros',
    icon: 'icon-minus'  
  },
  {
    title: true,
    name: 'Socios'
  },
  {
    name: 'Socios',
    url: '/socios',
    icon: 'icon-people'
  },
  {
    title: true,
    name: 'Negocios'
  },
  {
    name: 'Negocios',
    url: '/negocios',
    icon: 'cui-home '
  },
  {
    title: true,
    name: 'Productos'
  },
  /*
  {
    name: 'Prestamos',
    url: '/prestamos/solicitudes',
    icon: 'fa fa-money '
  },*/
  {
    name: 'Ahorros',
    url: '/ahorros',
    icon: 'material-icons-outlined ref-savings'
   
  },
  {
    name: 'Plazo Fijo',
    url: '/plazofijos',
    icon: 'fa fa-bank '
  },
  {
    title: true,
    name: 'Administración'
  },
  {
    name: 'Servicios',
    url: '/servicios',
    icon: 'fa fa-bar-chart  '
  },
  {
    title: true,
    name: 'Configuración'
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    icon: 'cui-user  '
  },
  {
    divider: true
  },
];
