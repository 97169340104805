import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../auth/services';
import { SideNavItems, SideNavSection } from '../models';
import { NavigationService } from '../services';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
    selector: 'sb-side-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];
    permissions
    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;

    constructor(private authenticationService:AuthenticationService, public userService: UserService, public navigationService: NavigationService) {
        this.permissions = this.authenticationService.getPermissions();
    }

    ngOnInit() {

        for(let m = 0; m<this.sideNavSections.length;m++){
            this.sideNavSections[m].items = this.sideNavSections[m].items.filter(i => this.permissions[i] ? this.permissions[i].read : false  )
        }
        this.sideNavSections = this.sideNavSections.filter(m => m.items.length)

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
