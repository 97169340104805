// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all {
    /* height: 5rem !important; */
    height: 100vh !important;
  }
.img-login{
  max-height: 5rem;
  margin-bottom: 18px;
}
  `, "",{"version":3,"sources":["webpack://./src/app/views/mfa/mfa/mfa.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,wBAAwB;EAC1B;AACF;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".all {\n    /* height: 5rem !important; */\n    height: 100vh !important;\n  }\n.img-login{\n  max-height: 5rem;\n  margin-bottom: 18px;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
