export const environment = {
  production: false,
  BANK_NAME:'coopera',
  TOKEN_NAME: 'access_token', 
  SYMBOL_APOR: "<> S/",
  APIREST:'https://web-backend.dev.platia.app',
  WSROUTE: 'wss://web-backend-api.dev.platia.app',
  RECAPTCHA_V3_SITE_KEY:'6LdT2XMnAAAAAH7R6-nKRKsvluMdFRVhVRPD3sC3',
  firebase: {
    projectId: 'dev-platia-app',
    appId: '1:2649961518:web:fe6d00e594686b995bdbbf',
    databaseURL: 'https://dev-platia-app-default-rtdb.firebaseio.com',
    storageBucket: 'dev-platia-app.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBlSzrLWmGktvitzmZEEL2h161SaTU_WFc',
    authDomain: 'dev-platia-app.firebaseapp.com',
    messagingSenderId: '2649961518',
    measurementId: 'G-PHMFDQJKEN',
  },
  BANKS: [
    {
        "code": "anqastech",
        "description": "Anqas Tech"
    },
    {
        "code": "cajamaya",
        "description": "Caya Maya"
    },
    {
        "code": "cajapaya",
        "description": "Caja Paya"
    },
    {
        "code": "coopera",
        "description": "Coopac Coopera"
    },
    {
        "code": "multicaja",
        "description": "MultiCaja"
    },
    {
        "code": "pagaya",
        "description": "PagaYa"
    }
]
};
